@font-face {
  font-family: "Austin Roman";
  src: url("Austin-Roman-Web.eot");
  src: url("Austin-Roman-Web.eot?#iefix") format("embedded-opentype"),
    url("Austin-Roman.woff2") format("woff2"),
    url("Austin-Roman-Web.woff") format("woff"),
    url("Austin-Roman.ttf") format("truetype"),
    url("Austin-Roman.svg#Austin-Roman") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Austin Light";
  src: url("Austin-Light.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Austin Italic";
  src: url("Austin-Italic.otf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Futura";
  src: url("Futura-Book.eot");
  src: url("Futura-Book.eot?#iefix") format("embedded-opentype"),
    url("Futura-Book.woff2") format("woff2"),
    url("Futura-Book.woff") format("woff"),
    url("Futura-Book.ttf") format("truetype"),
    url("Futura-Book.svg#Futura-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura Bk";
  src: url("Futura-Book.eot");
  src: url("Futura-Book.eot?#iefix") format("embedded-opentype"),
    url("Futura-Book.woff2") format("woff2"),
    url("Futura-Book.woff") format("woff"),
    url("Futura-Book.ttf") format("truetype"),
    url("Futura-Book.svg#Futura-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura Md BT";
  src: url("FuturaBT-Bold.eot");
  src: url("FuturaBT-Bold.eot?#iefix") format("embedded-opentype"),
    url("FuturaBT-Bold.woff2") format("woff2"),
    url("FuturaBT-Bold.woff") format("woff"),
    url("FuturaBT-Bold.ttf") format("truetype"),
    url("FuturaBT-Bold.svg#FuturaBT-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Futura Lt";
  src: url("Futura-Light.eot");
  src: url("Futura-Light.eot?#iefix") format("embedded-opentype"),
    url("Futura-Light.woff2") format("woff2"),
    url("Futura-Light.woff") format("woff"),
    url("Futura-Light.ttf") format("truetype"),
    url("Futura-Light.svg#Futura-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Didot-HTF-L16-Light";
  src: url("Didot-HTF-L16-Light.eot");
  src: url("Didot-HTF-L16-Light.eot?#iefix") format("embedded-opentype"),
    url("Didot-HTF-L16-Light.svg#Didot-HTF-L16-Light") format("svg"),
    url("Didot-HTF-L16-Light.ttf") format("truetype"),
    url("Didot-HTF-L16-Light.woff") format("woff"),
    url("Didot-HTF-L16-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
